<template>
  <div class="view">
    <div class="registerNewAgency">
      <div class="container"> 
        <div class="tabPanel">
          <div class="tabPanelContent --formPanel"> 
            <form action="" class="formContainer">
              <div class="inputContainer">
                <label for="agencyName">Agency Name</label>
                <input type="text" id="agencyName" name="agencyName" placeholder="Insert Agency Name">
              </div>
              <div class="inputContainer">
                <label for="phone">Phone</label>
                <input type="tel" id="phone" name="phone" placeholder="Insert Phone Number">
              </div>
              <div class="inputContainer">
                <label for="address">Address</label>
                <input type="text" id="address" name="address" placeholder="Insert Address">
              </div>
              <div class="inputContainer">
                <label for="contact">Contact</label>
                <input type="text" id="contact" name="contact" placeholder="Insert Contact">
              </div>
              <div class="inputContainer">
                <label for="location">Location</label>
                <input type="text" id="location" name="location" placeholder="Insert Location">
              </div>
              <div class="inputContainer">
                <label for="email">Email</label>
                <input type="email" id="email" name="email" placeholder="Insert Email">
              </div>
              <div class="inputContainer">
                <label for="stateProvince">State/Province</label>
                <input type="text" id="stateProvince" name="stateProvince" placeholder="Insert State/Province">
              </div>
              <div class="inputContainer">
                <label for="iataNumber">IATA Nº (if you have it)</label>
                <input type="text" id="iataNumber" name="iataNumber" placeholder="Insert IATA Number">
              </div>
              <div class="inputContainer">
                <label for="country">Country</label>
                <input type="text" id="country" name="country" placeholder="Insert Country">
              </div>
              <div class="inputContainer">
                <label for="usersQuantity">Users Quantity</label>
                <input type="text" id="usersQuantity" name="usersQuantity" placeholder="Insert Users Quantity">
              </div>
              <div class="inputContainer">
                <label for="platformsRequired">Platform(s) required</label>
                <br>
                    <label><input type="checkbox" id="kiuCommand" value="kiuCommand">KIU Command (Cryptic Solution)</label>
                <br>
                    <label><input type="checkbox" id="kiuClick" value="kiuClick">KIU Click (Graphic Solution)</label>
                <br>
              </div>
              <div class="inputContainer">
                <label for="clickUsers">Access E-mail for KIU Click users <br>(if you request KIU Click)</label>
                <textarea id="clickUsers" rows="5" name="clickUsers" placeholder="Administrator: 
Other users:"></textarea>
              </div>
              <div class="inputContainer">
                <label for="fmessage">Observation</label>
                <textarea id="fmessage" rows="5" name="fmessage" placeholder="Insert Observations"></textarea>
              </div>
              <button class="btn --outline" type="submit" value="Submit">Enviar</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
};

</script>

<style scoped lang="sass">
.formContainer
  display: flex 
  flex-direction: column
  align-items: flex-end
.registerNewAgency
  padding-top: 2rem
</style>
