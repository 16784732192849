<template>
  <div class="view">
    <div class="clients">
      <a class="clients__link" 
         v-for="(client,key) of clients" 
         :key="key" 
         :href='client.web' 
         target="_blank"
         >
         <span>{{ client.airline }}</span>
       </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'clients',
  data() {
    return {
      clients: [
        {
          "airline": "AEROCARIBE",
          "web": "http://www.aerocaribe.com.ve/"
        },
        {
          "airline": "AERODESIERTO",
          "web": "https://aerodesierto.com/"
        },
        {
          "airline": "AEROMAR",
          "web": "https://www.aeromar.mx/"
        },
        {
          "airline": "AEROPOSTAL",
          "web": "https://en.wikipedia.org/wiki/Aeropostal_Alas_de_Venezuela"
        },
        {
          "airline": "AEROREGIONAL",
          "web": "https://aeroregional.net/"
        },
        {
          "airline": "AIR CENTURY",
          "web": "https://www.aircentury.com/en/"
        },
        {
          "airline": "AIR PANAMA",
          "web": "https://www.airpanama.com/"
        },
        {
          "airline": "AIR ZIMBABWE",
          "web": "https://www.airzimbabwe.aero/"
        },
        {
          "airline": "ALBATROS",
          "web": "https://albatrosair.aero/"
        },
        {
          "airline": "AMERICAN JET",
          "web": "https://americanjet.com.ar/landing/?gclid=EAIaIQobChMIvpP78LHz6wIVEA-RCh3X3A3JEAAYASAAEgJ0zfD_BwE"
        },
        {
          "airline": "ARIELLA AIRLINES",
          "web": null
        },
        {
          "airline": "ARUBA AIRLINES",
          "web": "https://www.arubaairlines.com/"
        },
        {
          "airline": "ATSA",
          "web": "https://www.atsaairlines.com/"
        },
        {
          "airline": "AVIOR",
          "web": "https://www.avior.com.ve/"
        },
        {
          "airline": "BOA",
          "web": "https://www.boa.bo/BoAWebsite?AspxAutoDetectCookieSupport=1"
        },
        {
          "airline": "CONVIASA",
          "web": "http://www.conviasa.aero/"
        },
        {
          "airline": "DAP",
          "web": "https://dapairline.com/"
        },
        {
          "airline": "EASYFLY",
          "web": "https://www.easyfly.com.co/"
        },
        {
          "airline": "ECOJET",
          "web": "https://ecojet.bo/"
        },
        {
          "airline": "ESTELAR",
          "web": "https://www.flyestelar.com/"
        },
        {
          "airline": "FITS AIR",
          "web": "https://fitsair.com/"
        },
        {
          "airline": "FLY THE WORLD",
          "web": "https://www.goflytheworld.com/"
        },
        {
          "airline": "FLY VALUE JET",
          "web": "https://www.flyvaluejet.com/"
        },
        {
          "airline": "FLYEST",
          "web": "https://www.flyest.aero/"
        },
        {
          "airline": "FLYLOS ROQUES",
          "web": "http://www.flytolosroques.com/"
        },
        {
          "airline": "GRAN COLOMBIA",
          "web": "https://gcaair.com/home"
        },
        {
          "airline": "HAHN AIR",
          "web": "https://www.hahnair.com/en"
        },
        {
          "airline": "HELLO FLY",
          "web": "https://www.hellofly.it/"
        },
        {
          "airline": "LADE",
          "web": "http://www.lade.com.ar/"
        },
        {
          "airline": "LASA",
          "web": "https://www.aerolineaslasa.com/"
        },
        {
          "airline": "LASER",
          "web": "https://www.laserairlines.com/"
        },
        {
          "airline": "LUMINWINGS",
          "web": null
        },
        {
          "airline": "MANTIS",
          "web": "https://mantisserv.com/en/"
        },
        {
          "airline": "MAYAIR",
          "web": "https://www.mayair.com.mx/"
        },
        {
          "airline": "PARANAIR",
          "web": "https://www.paranair.com/"
        },
        {
          "airline": "PLUS ULTRA",
          "web": "https://plusultra.com/en/"
        },
        {
          "airline": "RAVSA VENEZOLANA",
          "web": "https://www.venezolana.aero/"
        },
        {
          "airline": "RED AIR",
          "web": null
        },
        {
          "airline": "RUTACA",
          "web": "http://www.flyrutaca.com/"
        },
        {
          "airline": "SANTA",
          "web": null
        },
        {
          "airline": "SASCA",
          "web": null
        },
        {
          "airline": "SATENA",
          "web": "https://www.satena.com/"
        },
        {
          "airline": "SKY HIGH",
          "web": "https://skyhighdominicana.com/home-2/"
        },
        {
          "airline": "STAR PERU",
          "web": "https://www.starperu.com/es"
        },
        {
          "airline": "SWIFT AIR / iAERO",
          "web": "https://iaerogroup.com/iaero-airways/"
        },
        {
          "airline": "TAC COLOMBIA",
          "web": "https://taccolombia.com/"
        },
        {
          "airline": "TAM BOLIVIA",
          "web": "https://tamep.bo/"
        },
        {
          "airline": "TAR LINEAS AEREAS",
          "web": "https://www.tarmexico.com/#/"
        },
        {
          "airline": "TRASMANDU",
          "web": "http://transmandu.com/"
        },
        {
          "airline": "TURPIAL",
          "web": "https://turpialairlines.com/"
        },
        {
          "airline": "WAYRA PERU",
          "web": null
        },
        {
          "airline": "WORLD 2 FLY (SP)",
          "web": null
        },
        {
          "airline": "WORLD 2 FLY (PT)",
          "web": null
        }
      ]
    }
  }
};
</script>

<style scoped lang="sass">
@import '../assets/colors.sass'
.clients
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  max-width: 960px
  margin: 0 auto
  padding: 2rem 0 0
.clients__link
  justify-content: center
  display: flex
  align-items: center
  text-transform: capitalize
  padding: 1rem 0
  text-decoration: none
  color: #0f2257
  font-size: .8rem
  box-shadow: inset 0 0 0 1px rgba(0,0,0,.05)
  border-radius: 4px
  margin: 1rem 0
  transition: background-color .2s ease
  &:hover
    background-color: #FAFAFA
@media (min-width: 768px)
  .clients__link
    flex-basis: 30%
</style>
