/* eslint-disable */

export default {
    data() {
        return {
            message_box: {
                ref_name: "message-box",
                $ref: null,
                active: false,
                title: "",
                message: "",
                button_caption: "",
            },
        }
    },
    mounted() {
        this.message_box.$ref = this.$refs[this.message_box.ref_name];
    },
    methods: {
        on_close_messagebox(event) {
            this.message_box.active = false;

            if (typeof this.message_box.events !== "undefined" && typeof this.message_box.events.on_close === "function") {
                this.message_box.events.on_close();
            }
        },

        on_show_messagebox(event) {
            switch (true) {
                case event.type === "success": {
                    this.success_messagebox(event.title, event.message, event.button_caption, event.events);
                    break;
                }

                case event.type === "error": {
                    this.error_messagebox(event.title, event.message, event.button_caption, event.events);
                    break;
                }

                default: {
                    this.messagebox(event.title, event.message, event.button_caption, event.events);
                    break;
                }
            }

            if (typeof this.message_box.events !== "undefined" && typeof this.message_box.events.on_show === "function") {
                this.message_box.events.on_show();
            }
        },

        messagebox(title, message, button_caption, events) {
            this.message_box['title'] = title;
            this.message_box['message'] = message;
            this.message_box['button_caption'] = button_caption;
            this.message_box['events'] = events;
            this.message_box['active'] = true;
        },

        success_messagebox(title, message, caption, events) {
            this.messagebox(
                typeof title === "undefined" ? this.$t('formFeedback.success.title') : title,
                typeof message === "undefined" ? this.$t('formFeedback.success.message') : message,
                typeof caption === "undefined" ? this.$t('formFeedback.btn') : caption,
                events);
        },

        error_messagebox(
            title, message, caption, events) {
            this.messagebox(
                typeof title === "undefined" ? this.$t('formFeedback.error.title') : title,
                typeof message === "undefined" ? this.$t('formFeedback.error.message') : message,
                typeof caption === "undefined" ? this.$t('formFeedback.btn') : caption,
                events);
        },
    }
};
