import Vue from 'vue'
import VueI18n from 'vue-i18n'

function getMessages() {
    const locales = require.context('.', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = Object.assign({}, locales(key));
        }
    });
    return messages
}

/** i18n **/
Vue.use(VueI18n);

let locale = navigator.language;
// let locale = 'es';
window.$i18n = locale;

if (locale && locale.length > 1) {
    locale = locale.split('-')[0];
}

const i18n = new VueI18n({
    locale: locale, // set locale
    messages: getMessages(), // set locale messages,
    fallbackLocale: 'en' // default language if not found
});

export default i18n
