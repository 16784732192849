import axios from 'axios';

const getHomePosts = (lang) => {
  let url = `${process.env.VUE_APP_API}/posts/home/`;
  return axios.get(url, {params: {lang: lang}});
};

const getPosts = (lang, nextToken = null) => {
  let url = `${process.env.VUE_APP_API}/posts/`;
  url = nextToken ? url + nextToken : url;
  return axios.get(url, {params: {lang: lang}});
};

const getArticle = (id) => {
  let url = `${process.env.VUE_APP_API}/posts/article/${id}`;
  return axios.get(url);
};

export {
  getPosts,
  getArticle,
  getHomePosts
};
