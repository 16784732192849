<template>
  <div id="lang-switcher" class="lang-switcher">
    <div id="currentLang" class="currentLang" @click.stop="toggle">
      {{ $t('lang.' + actualLanguage) }}
      <font-awesome-icon :icon="['fas', 'caret-down']" size="lg" class="ml-2"/>
      <!-- <img :src="getImage(actualLanguage)" alt=""> -->
    </div>
    <div id="containerLanguages" class="containerLanguages" v-show="show">
      <div v-for="item in availableLanguages"
           :key="'lang-' + item"
           @click="changeLanguage(item)"
           class="langItem"
      >
        {{ $t('lang.' + item) }}
        <!-- <img :src="getImage(item)" alt=""> -->
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  import { localize } from 'vee-validate/dist/vee-validate.full';
  import { validationMessages as _validation_en } from "@/locales/en.json";
  import { validationMessages as _validation_es } from "@/locales/es.json";

  localize({
    en: {
      messages: _validation_en,
      fields: {
        "platform[]": true
      }
    },
    es: {
      messages: _validation_es,
      fields: {
        "platform[]": true
      }
    }
  });

  export default {
    name: "i18nSwitcher",
    data() {
      return {
        show: false
      }
    },
    mounted() {
      localize(this.$i18n.locale);
    },
    computed: {
      availableLanguages: function () {
        return Object.keys(this.$i18n.messages).filter(item => item !== this.actualLanguage);
      },
      actualLanguage: function () {
        return this.$i18n.locale;
      }
    },
    methods: {
      toggle: function () {
        this.show = !this.show;
      },
      changeLanguage: function (targetLang) {
        this.$i18n.locale = targetLang;
        localize(targetLang);
        // window.$i18n = targetLang;
        this.show = false;
      },
      getImage: function (image) {
        return require(`@/assets/flags/flag-${image}.svg`);
      }
    }
  };
</script>

<style scoped lang="sass">
@import '../assets/colors.sass'
.lang-switcher
  justify-content: center
  position: relative
  margin: 0 0 0 1rem
  font-size: var(--font-sizexs)
  color: var(--color-white)
  cursor: pointer
.containerLanguages
  width: 100%
  background: linear-gradient(180deg, var(--color-overlay-70), var(--color-primary))
  box-shadow: 0 3px 4px 0px rgba(0, 0, 0, 0.05)
  padding: 0.5rem 0.5rem
  position: absolute
  right: -0.5rem
  top: 38px
  border-radius: 0 0 15px 15px
  transition: background-color 0.3s ease
  cursor: pointer
  &:hover
    // background-color: lightness(var(--color-primary), 5.4)
    background-color: var(--color-primary)
    img:hover
      box-shadow: 0 0 0 5px var(--color-overlay-l-20)
.currentLang
  display: flex
  align-items: center
  width: 100%
  justify-content: space-between
.langItem
  display: block
  padding: 5px 0
  text-align: center
img
  height: 20px
  transition: box-shadow 0.3s ease
  border-radius: 15px
</style>
