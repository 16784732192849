<template>
  <div v-view="viewHandler"></div>
</template>

<script>
export default {
  name: "infiniteScroll",
  methods: {
    viewHandler: function (e) {
      if (e.type === 'enter') {
        this.$emit('show-more')
      }
    }
  }
}
</script>

<style scoped lang="sass">
  div
    display: block
    width: 100%
    height: 25px
</style>
