<template>
    <div class="view">
        <div class="container">
            <br>
            <p class="lead">
                <span style="font-size: 1.5em;">ID de sesión actual <strong>{{ this.$session.id().split(":")[1] }}</strong></span><br>
                <br>
            </p>
            <p class="lead">
                <span>
                    Al presionar <strong>Aceptar</strong>, el ID de sesión será regenerado
                    <!--y ud. será redirigido a la pantalla de inicio-->.
                </span>
                <br><br>
            </p>
            <p class="lead">
                <span style="font-size: 1.5em;">Para confirmar la operación haga click en <strong>Aceptar</strong></span><br>
            </p>
            <p class="lead">
                <em>
                  <i>(Si desea cancelar, puede cerrar esta pestaña)</i>
                </em>
            </p>
          <br>
            <p class="lead">
                <button class="btn" @click.prevent="reset_session" style="display: inline-block;">
                  <span>Aceptar</span>
                </button>
            </p>

        </div>
    </div>
</template>

<script>
    /* eslint-disable */
    import Axios from 'axios';

    export default {
        components: {},
        data() {
            return {}
        },
        created() {
            if (!this.$session.exists()) {
                this.$session.start();
            }
        },
        methods: {
            giveFeedback(title, message, button_caption) {
                this.message_box['title'] = title;
                this.message_box['message'] = message;
                this.message_box['button_caption'] = button_caption;
                this.message_box['active'] = true;
            },
            giveSuccessFeedback(title, message, caption) {
                this.giveFeedback(
                    typeof title === "undefined" ? this.$t('formFeedback.success.title') : title,
                    typeof message === "undefined" ? this.$t('formFeedback.success.message') : message,
                    typeof caption === "undefined" ? this.$t('formFeedback.btn') : caption);
            },
            giveErrorFeedback(title, message, caption) {
                this.giveFeedback(
                    typeof title === "undefined" ? this.$t('formFeedback.error.title') : title,
                    typeof message === "undefined" ? this.$t('formFeedback.error.message') : message,
                    typeof caption === "undefined" ? this.$t('formFeedback.btn') : caption);
            },
            reset_session() {
                if (this.$session.exists()) {
                    this.$session.destroy();
                    // this.$session.start();
                    window.console.warn("===  ROUTER ===");
                    window.console.warn(this.$root.$router.currentRoute.fullPath);
                    window.console.warn("=== /ROUTER ===");
                    // this.$root.$router.push(this.$root.$router.currentRoute.fullPath);
                    this.$root.$router.push("/");
                }
                return false;
            }
        },
    };
</script>

<style scoped lang="sass">
    em
        font-style: italic

    .muted
        color: #777

    .view
        padding-bottom: 2rem

    .tabPanelContent
        display: block
        box-shadow: 0 7px 30px -13px rgba(0, 0, 0, 0.5)
        border-radius: 10px 10px var(--borderRadius-d1) 10px
        max-width: 960px
        margin: 0 auto
        margin-top: 3rem

    .inputComment
        display: block
        line-height: 0.8rem
        margin-top: 3px
        font-weight: 600

    @media (min-width: 768px)
        .formContainer
            flex-wrap: wrap
            flex-direction: row
            justify-content: space-between
            align-items: flex-start

            .inputContainer
                flex-basis: 50%
                box-sizing: border-box
                max-width: 48%

            .inputContainer:nth-child(3)
                flex-basis: 100%
                max-width: initial

            button
                margin-left: auto
                justify-self: flex-end
</style>
