<template>
<div class="intro" v-view="introAnimation">
  <div class="curtain"></div>
  <!-- <div class="clouds"></div> -->
<!--   <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 1365 626" style="enable-background:new 0 0 1365 626;" xml:space="preserve">
  <g class="circleGroup">
    <circle class="st2 circle" cx="683.1" cy="156.1" r="77.1"/>
    <circle class="st3 circle" cx="811.7" cy="219.1" r="77.1"/>
    <circle class="st4 circle" cx="843.2" cy="356.5" r="77.1"/>
    <circle class="st5 circle" cx="755" cy="469.9" r="77.1"/>
    <circle class="st6 circle" cx="611.3" cy="469.9" r="77.1"/>
    <circle class="st7 circle" cx="521.8" cy="356.5" r="77.1"/>
    <circle class="st8 circle" cx="555.8" cy="219.1" r="77.1"/>
  </g> 
  <g class="kiuWord">
    <path class="st9" d="M675.4,328.9c0-4.1,2.8-6.8,7.2-6.6c4.6,0.1,7,2,7,6.7c0,4.6-2.1,6.5-7,6.6
      C678.4,335.7,675.4,333.3,675.4,328.9z"/>
    <path class="st9" d="M683.1,384.9h-0.2c-3.5,0-6.3-2.8-6.3-6.3v-33.1c0-3.5,2.8-6.3,6.3-6.3h0.2c3.5,0,6.3,2.8,6.3,6.3v33.1
      C689.4,382.1,686.6,384.9,683.1,384.9z"/>
    <path class="st9" d="M741.6,383.4c0,0.8-0.7,1.5-1.5,1.5h-7.6c-0.8,0-1.5-0.7-1.5-1.5v-3.5c0-0.2-0.1-0.3-0.2-0.5
      c-1.7,1.3-2.3,1.8-3.9,2.9c-11.1,7.8-24.6,1.3-25.6-12.3c-0.5-6.4-0.6-11.2-0.5-17.6V340c0-0.8,0.7-1.5,1.5-1.5h8.9
      c0.8,0,1.5,0.7,1.5,1.5v12.9c0,4.8,0,9.6,0.1,14.4c0,3.5,1,6.6,4.5,8.1c4.7,2,12.1-3.5,12.1-8.8c0-1.2,0-1.9,0-3c0,0,0-0.5,0-0.7
      V340c0-0.8,0.7-1.5,1.5-1.5h8.9c0.8,0,1.5,0.7,1.5,1.5l0.1,2.7c0,4.6-0.1,9.2-0.1,13.8v6.3c0,0,0,0.1,0,0.2c0,3.6,0,7.2,0,10.7
      L741.6,383.4z"/>
    <path class="st9" d="M648.8,352.7L648.8,352.7l20-26.9c0.6-0.8,0.3-2.2-0.5-2.2c-2.2,0-10.5,0-10.5,0c-1.5,0-1.7-0.1-2.2,0.7
      l-18.9,27v-26c0-1-0.8-1.8-1.8-1.8h-9.2c-1,0-1.8,0.8-1.8,1.8v57.8c0,1,0.8,1.8,1.8,1.8h9.2c1,0,1.8-0.8,1.8-1.8v-28.9l18.9,29.9
      c0.6,0.9,0.7,0.9,2.2,0.9c0,0,8.3,0,10.5,0c0.7,0,1-1.7,0.5-2.6L648.8,352.7z"/>
  </g>
  <g class="KiuDiagramA">
    <path class="st0" d="M741.2,264.2c-0.2-5-2-9.5-4.7-13.6c-5.8-9-13.8-12-24-8.9c-12.7,4-23.2,12-34.2,19c-1.2,0.6-2.3,1.3-3.5,1.8
    c-3.3,1.5-3.3,3.1-0.5,5.3c3.4,2.7,6.6,5.8,10.1,8.7c7,5.8,13.3,12.6,21.5,16.9c5.7,3.1,11.6,4.9,18.3,2.8
    C735.8,292.5,743.4,278.5,741.2,264.2"/>
    <path class="st12" d="M678.3,260.6c11.1-6.9,21.5-15,34.2-19c10.1-3.1,18.2,0,24,8.9c2.6,4.1,4.5,8.6,4.7,13.6
      c-0.6,0.8-1.3,1.6-1.9,2.4c-9.3,12.2-20.8,14.5-33.7,9.9C695.7,273,686.4,267.7,678.3,260.6z"/>
  </g>
  <path class="st9 KiuDiagramB" d="M623.4,273.6c-0.2-3.7,0.8-7.5,2.6-11.1c5.3-10.3,15-13.6,25.2-8.1c9.9,5.4,17.4,14.1,26.1,21.1
      c1.6,1.3,1,2.1-0.4,2.9c-8,4.8-15.7,10-23.9,14.2C636.5,301.1,623.4,292.6,623.4,273.6z"/>

  </svg> -->

</div>
</template>

<script>
import { TimelineMax, Power1, Power3 } from 'gsap';

export default {
  data() {
    return {
      animated: false
    }
  },
  methods: {
    introAnimation: function(e) {
      if(e.percentInView >= 0 && !this.animated) {
        var introAnimationTl = new TimelineMax();
          introAnimationTl.to(".curtain", 0.3, {alpha: 0, delay: 1}, "tito")
          .set(".alertNews .bg, .alertNews .content", {display:"none", alpha:0})
          // .to(".clouds", 1, {alpha: 0}, "tito+=1.1")
          // .to(".clouds", 10, {x: -250, ease: Power3.easeOut}, "tito+=1")
          .from(".sliderCaption *", 0.35, {alpha: 0, x:20}, "tito+=1.7")
          .fromTo(".navComponent", 0.3, {scaleX:0, transformOrigin:'left center', ease: Power3.easeOut}, {scaleX:1}, "tito+=1.25") 
          .fromTo(".logo", 0.5, {alpha: 0}, {alpha: 1}, "tito+=1.75") 
          .fromTo(".actionContainer", 0.3, {alpha: 0}, {alpha: 1}, "tito+=2") 
          .fromTo(".alertNews", 0.35, {scaleX: 0, transformOrigin:'right center', ease: Power1.easeIn}, {scaleX:1}, "tito+=1.5")
          .set(".intro", {display:"none"}, "tito+=2.5")
          .set("body", {overflow:"auto"}, "tito+=2.5")
          .set(".alertNews .bg, .alertNews .content", {display:"flex"}, "tito+=2")
          .to(".alertNews .bg, .alertNews .content", 0.35, {alpha: 1}, "tito+=2")
          .staggerFrom(".hooper-indicators li", 0.3, {scaleX:0, transformOrigin:'left center', ease: Power1.easeOut}, 0.2, "tito+=1.3")
          // introAnimationTl.staggerFrom(".circle", 0.3, {alpha: 0}, 0.15)
          // .to(".circle", 0.5, {transformOrigin:'center center', attr:{cx:640, cy:300}}, 'cucu')
          // .to(".circleGroup", 0.5, {scale: 2, transformOrigin: 'center center', x:'81', y:'30'}, 'cucu')
          // .to(".kiuWord, .KiuDiagramA, .KiuDiagramB", 0.5, {alpha: 1}, 'tito')
          // ;
        this.animated = true;
      }
    }
  }
};

</script>

<style scoped lang="sass">
.intro
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  z-index: 100000
.curtain
  position: absolute
  width: 100%
  height: 100%
  top: 0
  left: 0
  background-color: #FFFFFF
  z-index: 4
// .clouds
//   position: absolute
//   width: 100%
//   height: 100%
//   top: 0
//   left: 0
//   background-color: transparent
//   // background-image: url('../assets/intro.png')
//   background-size: cover
//   background-repeat: no-repeat
//   background-position: right center
//   z-index: 3
// .st0
//   fill: #AC3A87
// .st1
//   fill: #FFFFFF
// .st2
//   fill: none
//   stroke: #81A8A6
//   stroke-width: 0.5
// .st3
//   fill: none
//   stroke: #796B8D
//   stroke-width: 0.5
// .st4
//   fill: none
//   stroke: #B68043
//   stroke-width: 0.5
// .st5
//   fill: none
//   stroke: #E6CF2B
//   stroke-width: 0.5
// .st6
//   fill: none
//   stroke: #A03E37
//   stroke-width: 0.5
// .st7
//   fill: none
//   stroke: #CFC6A2
//   stroke-width: 0.5
// .st8
//   fill: none
//   stroke: #96A54C
//   stroke-width: 0.5
// .st9
//   fill: #2E2A62
// .st10
//   fill: #5A4F70
// .st11
//   fill: #796B8D
// .st12
//   fill:#55296A
// .kiuWord, .KiuDiagramA, .KiuDiagramB
//   opacity: 0
</style>
