
import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home.vue';
import Solutions from '../views/Solutions.vue';
import Product from '../views/Product.vue';
import About from '../views/About.vue';
import Philosophy from "../views/About/Philosophy";
import Contact from '../views/Contact.vue';
import Agencies from '../views/Agencies.vue';
import NewAgency from '../views/Agencies/NewAgency.vue';
import DeviceReset from '../views/Agencies/DeviceReset.vue';
import DeviceResetConfirm from '../views/Agencies/DeviceResetConfirm.vue';
import UserReset from '../views/Agencies/UserReset.vue';
import UserResetConfirm from '../views/Agencies/UserResetConfirm.vue';
import SessionReset from '../views/SessionReset.vue';
import News from '../views/News.vue';
import Article from '../views/Article.vue';
// import NewsUs from '../views/NewsUs.vue';
import Services from '../views/Services.vue';
import Board from "../views/About/Board";
import Milestones from "../views/About/Milestones";
import Clients from "../views/Clients";
import Background from "../views/About/Background";

Vue.use(VueRouter);

const contactRouteCheck = (route) => { /*
  window.console.warn("=== contactRouteCheck ===");
  window.console.warn(route);
  window.console.warn("===/contactRouteCheck ==="); */

  if (route.params.topic !== undefined
  && ["helpdesk", "commercial"].indexOf(route.params.topic) === -1) {
    router.replace("/contact");
  }

  return route.params;
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/solutions',
    name: 'solutions',
    component: Solutions
  },
  {
    path: '/solutions/:productName',
    name: 'product',
    component: Product
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/about/philosophy',
    name: 'philosophy',
    component: Philosophy
  },
  {
    path: '/about/milestones',
    name: 'milestones',
    component: Milestones
  },
  {
    path: '/about/background',
    name: 'background',
    component: Background
  },
  {
    path: '/about/board',
    name: 'board',
    component: Board
  },
  {
    path: '/contact/:topic?',
    name: 'contact',
    component: Contact,
    props: contactRouteCheck
  },
  {
    path: '/agencies',
    name: 'agencies',
    component: Agencies
  },
  {
    path: '/agencies/new-agency',
    name: 'new-agency',
    component: NewAgency
  },
  {
    path: '/agencies/device-reset',
    name: 'device-reset',
    component: DeviceReset
  },
  {
    path: '/agencies/device-reset/confirm/:id',
    name: 'device-reset-confirm',
    component: DeviceResetConfirm,
    props: true
  },
  {
    path: '/agencies/user-reset',
    name: 'user-reset',
    component: UserReset
  },
  {
    path: '/agencies/user-reset/confirm/:id',
    name: 'user-reset-confirm',
    component: UserResetConfirm,
    props: true
  },
  {
    path: '/news',
    name: 'news',
    component: News
  },
  {
    path: '/news/article/:id',
    name: 'article',
    component: Article
  },
  {
    path: '/services',
    name: 'services',
    component: Services
  },
  {
    path: '/session/reset',
    name: 'session-reset',
    component: SessionReset
  },
  {
    path: '/solutions/pss/clients',
    name: 'clients',
    component: Clients
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_MODULE_PREFIX,
  routes,
  scrollBehavior() {
      return {x: 0, y: 0}
  }
});

/** google analitycs **/
router.afterEach((to) => {
  // eslint-disable-next-line
  gtag('config', window.GA_TRACKING_ID, {
    page_path: to.fullPath,
    app_name: window.GA_APP_NAME,
    send_page_view: true
  })
});

export default router
