<template>
    <div>
        <input type="hidden" ref="session_id" id="session_id" :value="this.sessionID"/>
        <input type="hidden" ref="client_ip" id="client_ip" :value="this.clientIP"/>
    </div>
</template>

<script>
    /* eslint-disable */
    import Axios from 'axios';

    export default {
        data() {
            return {
                sessionID: null,
                clientIP: null
            }
        },
        created() {
            let ctx = this;

            if (!this.$session.exists()) {
                this.$session.start();
            }

            this.sessionID = this.$session.id().replace("sess:", "");
            window.sessionID = this.sessionID;

            Axios.get("https://icanhazip.com").then((response) => {
                window.clientIP = ctx.clientIP;
                ctx.clientIP = response.data.trim();
            });
        },
        mounted() {
            window.clientIP = this.$refs["client_ip"].value;
            window.sessionID = this.$refs["session_id"].value;

            // window.console.warn("===  MOUNTED: SESSION ===");
            // window.console.warn(this.$refs.session_id.value);
            // window.console.warn(this.$refs.client_ip.value);
            // window.console.warn("=== /MOUNTED: SESSION ===");
        }
    };
</script>