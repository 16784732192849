import Vue from 'vue'

export default Vue.extend({
  props: {
    post: {
      required: true
    }
  },
  name: "Post",
  methods: {
    goPost: function () {
      this.$router.push({ name: 'article', params: { id: this.post.id } })
    }
  },
  computed: {
    image: function () {
      return this.post.images ? this.post.images[0].url : require('@/assets/article.svg')
    },
    created: function () {
      return new Date(this.post.published).toLocaleDateString(this.$i18n.locale)
    }
  }
})
