/* eslint-disable */
export default {
    methods: {
        messagebox(title, message, button_caption, events) {
            this.$emit("show_messagebox", { title, message, button_caption, events });
        },
        success_messagebox(title, message, button_caption, events) {
            this.$emit("show_messagebox", { title, message, button_caption, events, type: "success" });
        },
        error_messagebox(title, message, button_caption, events) {
            this.$emit("show_messagebox", { title, message, button_caption, events, type: "error" });
        },
        close_messagebox() {
            this.$emit("close_messagebox");
        },
    }
};
